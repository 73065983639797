<!--
 * @Descripttion: 找回密码
 * @Author: zyr
 * @Date: 2021-05-27 22:41:06
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-16 20:13:36
-->
<template>
  <div class="wrapper">
    <h2 class="header-tlt">信箱</h2>
    <h3 class="header-desc color-light">找回密码</h3>
    <van-form @submit="onSubmit" @failed="onFail" :show-error-message="false" validate-first>
      <div class="form-item hack-border-bottom">
        <div class="flex-grow">
          <van-field v-model="phone" placeholder="手机号码" maxlength="11" type="digit"
             name="phone" autocomplete="off" :error="false"
            :rules="[{
              message: '手机号码格式不正确',
              pattern: /^1[3-9]\d{9}$/
            }]"
          />
        </div>
      </div>
      <div class="form-item hack-border-bottom form-item-last">
        <div class="flex-grow code">
          <van-field v-model="code" placeholder="请输入短信验证码" name="code" type="digit" autocomplete="off" maxlength="6"  />
        </div>
        <span class="send-code" @click="getCode">{{timer ? time + 's' : '发送验证码'}}</span>
      </div>
      <van-button :disabled="isDisable" :color="'#5460FE'" block native-type="submit"
        :style="isDisable ? {background: '#8F92BB', 'border-color': '#8F92BB'} : ''">确定</van-button>
    </van-form>
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { checkCode, getCode } = API
export default {
  name: 'FindPass',
  data () {
    return {
      phone: '',
      code: '',
      total: 59,
      time: 59,
      timer: null,
      canSend: true,
      canClick: true
    }
  },
  computed: {
    isDisable: function () {
      if (this.phone && this.code) return false
      else return true
    }
  },
  methods: {
    onFail (info) {
      const { errors: [{ message }] } = info
      this.$toast(message)
    },
    onSubmit (values) {
      if (!this.canClick) return
      this.canClick = false
      this.$axios.post(checkCode, values).then(({ code, msg }) => {
        this.canClick = true
        if (code === 0) {
          this.$store.commit('setPhoneInfo', { ...values })
          this.$router.push('/reset')
        } else this.$toast(msg)
      })
    },
    getCode () {
      if (!/^1[3-9]\d{9}$/.test(this.phone)) return this.$toast('手机号码格式不正确')
      if (this.timer) return
      if (!this.canSend) return
      this.canSend = false
      this.$axios.post(getCode, { phone: this.phone, type: 0 }).then(({ code, msg }) => {
        this.canSend = true
        if (code === 0) {
          let time = this.total
          this.time = this.total
          this.timer = setInterval(() => {
            time--
            if (time <= 0) {
              clearInterval(this.timer)
              this.timer = null
            }
            this.time = time
          }, 1000)
        } else this.$toast(msg)
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.phone = ''
      vm.code = ''
      vm.canSend = true
    })
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timer)
    this.timer = null
    this.time = 60
    next()
  }
}
</script>
<style  lang='less'>
@import '../../assets/less/flex.less';
.van-cell{
  padding: 0;
  font-size: .px2rem(32px)[@rem];
}
.van-field__control{
  color: #101112;
}
.van-button{
  height: .px2rem(108px)[@rem];
  border-radius: .px2rem(20px)[@rem];
  margin-top: .px2rem(250px)[@rem];
}
.van-button--disabled{
  opacity: 1;
}
.van-button__text{
  font-size: .px2rem(36px)[@rem];
}
</style>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
</style>
